
import { VerticalJob,verticalJobs } from '../../app/verticals/vertical-jobs-constants';
import { NamedEntity } from '../abstract/namedEntity';

export class Vertical extends NamedEntity{

    jobs:VerticalJob[]=[];
    topJobs:VerticalJob[];
    url:string='';
    jobName:string='';
    imageUrl='';
    mostPopularJobName:string=''
    iconColor:string="var(--action)"
    mainVerticalNameUrl? :string = ''
    skilledImg:string
    constructor(vertical:any){
        super();
        this.name=vertical.name;
        this.url=vertical.url;
        this.jobName=vertical.jobName;
        this.imageUrl=vertical.imageUrl;
        this.mostPopularJobName = vertical.mostPopularJobName;
        this.jobs = verticalJobs.filter(job=>job.vertical.name==this.name)
        this.topJobs = this.jobs.slice(0,7);
        this.iconColor = vertical.iconColor
        this.mainVerticalNameUrl = vertical?.mainVerticalNameUrl
        this.skilledImg = vertical.skilledImg
    }
}

import { Vertical } from '../../models/vertical/vertical';

export const verticalList: Vertical[] = [
  new Vertical({
    name: "Development",
    url: "hire-developers",
    jobName: "Developers",
    imageUrl: "../assets/images/verticals/vertical-list/icons/development.svg",
    iconColor: "var(--action)",
    mostPopularJobName: "Application Developer",
    skilledImg: "/assets/images/verticals/people/skilled-6"

  }),
  new Vertical({
    name: "Design",
    url: "hire-designers",
    jobName: "Designers",
    imageUrl: "../assets/images/verticals/vertical-list/icons/design.svg",
    iconColor: "var(--barbie-pink)",
    mostPopularJobName: "Web Designer",
    skilledImg: "/assets/images/verticals/people/skilled-30"
  }),
  new Vertical({
    name: "Test & QA",
    url: "hire-test-engineers",
    jobName: "Testers",
    imageUrl: "../assets/images/verticals/vertical-list/icons/test-&-qa.svg",
    iconColor: "var(--coral)",
    mostPopularJobName: "Test Engineer",
    skilledImg: "/assets/images/verticals/people/skilled-16"
  }),
  new Vertical({
    name: "AI & Data Science",
    url: "hire-artificial-intelligence",
    jobName: "Artificial Intelligence Engineers",
    imageUrl: "../assets/images/verticals/vertical-list/icons/ai-&-data-science.svg",
    iconColor: "var(--pending)",
    mostPopularJobName: "AI Engineer",
    skilledImg: "/assets/images/verticals/people/skilled-3"
  }),
  new Vertical({
    name: "Product Management",
    url: "hire-product-managers",
    jobName: "Product Managers",
    imageUrl: "../assets/images/verticals/vertical-list/icons/product-management.svg",
    iconColor: "var(--action)",
    mostPopularJobName: "Product Manager",
    skilledImg: "/assets/images/verticals/people/skilled-27"
  }),
  new Vertical({
    name: "Project Management",
    url: "hire-project-managers",
    jobName: "Project Managers",
    imageUrl: "../assets/images/verticals/vertical-list/icons/project-management.svg",
    iconColor: "var(--coral)",
    mostPopularJobName: "Project Manager",
    skilledImg: "/assets/images/verticals/people/skilled-8"
  }),
  new Vertical({
    name: "Business & Marketing",
    url: "hire-business-marketing",
    jobName: "Business & Marketing Experts",
    imageUrl: "../assets/images/verticals/vertical-list/icons/business-&-marketing.svg",
    iconColor:"var(--highlight)",
    mostPopularJobName: "Marketing or Sales Expert",
    skilledImg: "/assets/images/verticals/people/skilled-24"
  }),
  new Vertical({
    name: "Top Management",
    url: "hire-top-managers-and-executives",
    jobName: "Top Managers & Executives",
    imageUrl: "../assets/images/verticals/vertical-list/icons/top-management.svg",
    iconColor:"var(--highlight)",
    mostPopularJobName: "Top Manager",
    skilledImg: "/assets/images/verticals/people/skilled-28"
  }),
  new Vertical({
    name: "Customer Support",
    url: "support-outsourcing",
    jobName: "Customer Support",
    imageUrl: "../assets/images/verticals/vertical-list/icons/customer-support.svg",
    iconColor:"var(--slate)",
    mostPopularJobName: "Support Engineer",
    skilledImg: "/assets/images/verticals/people/skilled-29"
  }),
  new Vertical({
    name: "Networks",
    url: "hire-network-engineers",
    jobName: "Network Engineers",
    imageUrl: "../assets/images/verticals/vertical-list/icons/network.svg",
    iconColor: "var(--purple)",
    mostPopularJobName: "Networks Engineer",
    skilledImg: "/assets/images/verticals/people/skilled-9"
  }),
  new Vertical({
    name: "IT & Operations",
    url: "it-operations-outsourcing",
    jobName: "IT & Operations Engineers",
    imageUrl: "../assets/images/verticals/vertical-list/icons/it-&-operations.svg",
    iconColor: "var(--coral)",
    mostPopularJobName: "IT Operations Engineer",
    skilledImg: "/assets/images/verticals/people/skilled-7"
  }),
  new Vertical({
    name: "Cloud",
    url: "hire-cloud-experts",
    jobName: "Cloud Experts",
    imageUrl: "../assets/images/verticals/vertical-list/icons/cloud.svg",
    iconColor: "var(--dark-sky-blue)",
    mostPopularJobName: "Cloud Architect",
    skilledImg: "/assets/images/verticals/people/skilled-23"
  }),
  new Vertical({
    name: "Security",
    url: "hire-security-experts",
    jobName: "Security Experts",
    imageUrl: "../assets/images/verticals/vertical-list/icons/security.svg",
    iconColor: "var(--pending)",
    mostPopularJobName: "Security Engineer",
    skilledImg: "/assets/images/verticals/people/skilled-4"
  }),
  new Vertical({
    name: "Storage & Backup",
    url: "hire-storage-backup-engineers",
    jobName: "Storage & Backup Engineers",
    imageUrl: "../assets/images/verticals/vertical-list/icons/storage-&-backup.svg",
    iconColor: "var(--dark-sky-blue)",
    mostPopularJobName: "Storage & Backup Administrator",
    skilledImg: "/assets/images/verticals/people/skilled-21"
  }),
  new Vertical({
    name: "ERP",
    url: "hire-erp-consultants",
    jobName: "ERP Consultants",
    imageUrl: "../assets/images/verticals/vertical-list/icons/erp.svg",
    iconColor: "var(--coral)",
    mostPopularJobName: "ERP Expert",
    skilledImg: "/assets/images/verticals/people/skilled-59"
  }),
  new Vertical({
    name: "Embedded Engineering",
    url: "hire-embedded-engineers",
    jobName: "Embedded Engineers",
    imageUrl: "../assets/images/verticals/vertical-list/icons/embedded-engineering.svg",
    iconColor: "var(--action)",
    mostPopularJobName: "Embedded Software Engineer",
    skilledImg: "/assets/images/verticals/people/skilled-32"
  })
];


export const duplicateVerticalList: Vertical[] = [
  new Vertical({
    name: "Test & QA",
    url: "hire-quality-assurance",
    jobName: "Quality Assurance Engineers",
    imageUrl: "../assets/images/verticals/vertical-list/icons/test-&-qa.svg",
    mostPopularJobName : 'Test Engineer',
    iconColor: "var(--coral)",
    mainVerticalNameUrl: 'hire-test-engineers'
  }),
  new Vertical({
    name: "AI & Data Science",
    url: "hire-data-scientists",
    jobName: "Data Scientists",
    imageUrl: "../assets/images/verticals/vertical-list/icons/ai-&-data-science.svg",
    iconColor: "var(--pending)",
    mostPopularJobName: "AI Engineer",
    mainVerticalNameUrl: "hire-artificial-intelligence",
  }),
]

function verticalIndex(verticalList, urlPath) {
  return verticalList.map(vertical => vertical.url).indexOf(urlPath);
}

export { verticalIndex as getVerticalIndex };


